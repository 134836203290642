import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import './blogForm.css'
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Loader from './LoaderComponent';
import { useParams } from 'react-router-dom';
import AlertContext from "../context/alertAndPopUp/alertAndPopUpContext";


const BlogForm = () => {
  const url = process.env.REACT_APP_BACKEND_SERVER_URL || 'http://localhost:4000/api/v1';
  const {id} = useParams() || null;
  const [FirstParagraph, setFirstParagraph] = useState('');
  const [SecondParagraph, setSecondParagraph] = useState('');
  const [ThirdParagraph, setThirdParagraph] = useState('');
  const [FourthParagraph, setFourthParagraph] = useState('');
  const [count, setCount] = useState(0);
  const [loading,setLoading] = useState(false)
  const [title, setTitle] = useState('');
  const { alert } = useContext(AlertContext);
  //for ract quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }], // Color picker for text
      [{ background: [] }], // Background color picker
      [{ script: "sub" }, { script: "super" }], // Superscript/subscript
      [{ list: "ordered" }, { list: "bullet" }],
      [{ align: [] }],
      ["link"],
      ["clean"], // Remove formatting button
    ],
  };
  
  const [images, setImages] = useState({
    image1: "",
    image2: "",
    image3: "",
  });

  //fill the form with the post you want to edit
  useEffect(()=> {
  if (id){
      getPost(id)
    }
  },[id])

  //get the post you want to update and update the states
  const getPost = async (id) => {
    try {
      const response = await axios.get(`${url}/posts/${id}/edit`);
      setTitle(response.data.Title)
      setFirstParagraph(response.data.paragraph1)
      setSecondParagraph(response.data.paragraph2)
      setThirdParagraph(response.data.paragraph3)
      setFourthParagraph(response.data.paragraph4)
      setImages({
        image1: response.data.image1,
        image2: response.data.image2,
        image3: response.data.image3,
      })
      console.log(response.data)
    } catch (error) {
      console.log(error.message)
    }
  };

  const handleCount= (e)=>{
    e.preventDefault()
    setCount(count+1)
    };
  const handleChange = (e) => {
    const {value } = e.target;
    setTitle(value);
    console.log("FirstParagraph before appending:", FirstParagraph)
  };
  const handleImage = (e) => {
    const { name, files } = e.target;
    setImages((prev) => ({ ...prev, [name]: files[0] }));
    console.log(images);
  };


  const onSubmit = async (e) => {
    e.preventDefault()
    if (FirstParagraph === '' || SecondParagraph === '' || ThirdParagraph === '' || FourthParagraph === '' ){
      alert("form cant be empty")
      return
    };
    // Create FormData object to handle file uploads
    const formData = new FormData();
    formData.append('title', title);
    formData.append('FirstParagraph', FirstParagraph);
    formData.append('SecondParagraph', SecondParagraph);
    formData.append('ThirdParagraph', ThirdParagraph);
    formData.append('FourthParagraph', FourthParagraph);
    formData.append('image1', images.image1);
    formData.append('image2', images.image2);
    formData.append('image3', images.image3);
    
    console.log(formData.get("FirstParagraph"))
    try {
      setLoading(true)
      if(id){
        console.log('meee')
        const res = await axios.put(`${url}/posts/edit/${id}`,formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(res)
      }else{
        const res = await axios.post(`${url}/posts/create`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log(formData.get("FirstParagraph"))
        console.log(res.data)
        console.log("clicked")
        // Reset form fields after successful submission;
        setTitle('')
        setFirstParagraph('')
        setSecondParagraph('')
        setThirdParagraph('')
        setFourthParagraph('')
        setImages({
          image1: "",
          image2: "",
          image3: "", 
        });
      }
      setLoading(false)
      setCount(0)
      alert('Blog post submitted successfully');
    } catch (error) {
      setLoading(false)
      console.error('Failed to submit blog post', error);
      alert('Blog post not submitted check parameters')
      
    }
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <div className='bg-white rounded-md m-5 '>
      <div className='justify-center w-[70%] mx-auto  flex flex-col items-start '>
        <h1 className='text-2xl font-bold capitalize text-center py-5 mx-auto'>
          create blog post
        </h1>
          <form onSubmit={onSubmit} className='flex flex-col gap-5 w-full items-start'>
            <div className=' flex flex-row-reverse gap-4 text-left w-full'>
              <input type="text" name='title' className='bg-slate-500 opacity-[80%] text-white w-full rounded-md p-3 focus:outline-blue-900 placeholder:italic placeholder:text-[1rem] placeholder:font-semibold' onChange={handleChange} value={title} placeholder='Blog Title' required/>
            </div>
              <div className=' flex flex-row-reverse gap-4 w-full justify-end items-baseline'>
                <input type='file' name='image1' accept="image/*" onChange={handleImage} required={id === undefined}/>
                <label htmlFor="image1" className='px-4 py-3 rounded-xl bg-[#228699] text-white  min-w-[200px] uppercase text-center' >title image:</label>
              </div>
              <div className=' flex flex-row-reverse gap-4 text-left w-full justify-end items-baseline'>
                <input type='file' name='image2' accept="image/*" onChange={handleImage} required={id === undefined}/>
                <label htmlFor="image2" className='px-4 py-3 rounded-xl bg-[#228699] text-white text-center uppercase min-w-[200px]'>Seondary image</label>
              </div>
              <div className=' flex flex-row-reverse gap-4 text-left w-full justify-end items-baseline'>
                <input type='file' name='image3' accept="image/*" onChange={handleImage} required={id === undefined}/>
                <label htmlFor="image3" className='px-4 py-3 rounded-xl bg-[#228699] text-white text-center uppercase min-w-[200px]'>Seondary image</label>
              </div>
            <div className='w-full '>
              <p className='text-black font-semibold text-xl pt-3'>Add the First Paragraph</p>
              <ReactQuill type="text" name='FirstParagraph' className='opacity-[80%] w-full rounded-md p-4 placeholder:font-bold placeholder:text-2xl text-white h-full bg-slate-500' value={FirstParagraph} placeholder='First Paragraph' onChange={setFirstParagraph} modules={modules} />
            </div>
            {
              count > 0 && (
            <div className={'w-full'}>
              <p className='text-black font-semibold text-xl pt-3'>Add the Second Paragraph</p>
              <ReactQuill type="text" name='SecondParagraph' className='opacity-[80%] w-full rounded-md p-4 placeholder:font-bold placeholder:text-2xl text-white h-full bg-slate-500' value={SecondParagraph} placeholder='Second Paragraph' onChange={setSecondParagraph} modules={modules}/>
            </div>)
            }
            {
              count > 1 && (
            <div className={'w-full'}>
              <p className='text-black font-semibold text-xl pt-3'>Add the Third Paragraph</p>
              <ReactQuill type="text" name='ThirdParagraph' className='opacity-[80%] w-full rounded-md p-4 placeholder:font-bold placeholder:text-2xl text-white h-full bg-slate-500' value={ThirdParagraph} placeholder='Third Paragraph' onChange={setThirdParagraph} modules={modules}/>
            </div>)
            }
            {
              count > 2 && (
            <div className={'w-full'}>
              <p className='text-black font-semibold text-xl pt-3'>Add the Fourth Paragraph</p>
              <ReactQuill type="text" name='FourthParagraph' className='opacity-[80%] w-full rounded-md p-4 placeholder:font-bold placeholder:text-2xl text-white h-full bg-slate-500' value={FourthParagraph} placeholder='Fourth Paragraph' onChange={setFourthParagraph} modules={modules}/>
            </div>)
            }
              <Fab size="small" color="primary" aria-label="add" className='ml-[100px]' onClick={handleCount} disabled={count >=3 }>
              <AddIcon />
              </Fab>
              <button type='submit' className='w-full bg-[#228699] text-white p-4 rounded-lg hover:opacity-90' disabled={loading}>
                submit
              </button>
              </form>
              <div className='mt-4 ml-auto'>
            </div>
      </div>
    </div>
    )
  }


          {/* <div className='w-full hidden'>
            <p className='text-black font-semibold text-xl pt-3'>Add the First Paragraph</p>
            <ReactQuill type="text" name='FirstParagraph' className='opacity-[80%] w-full rounded-md p-4 placeholder:font-bold placeholder:text-2xl text-white h-full bg-slate-500' value={data.FirstParagraph} placeholder='First Paragraph' />
          </div> 
           <div className=' flex flex-row-reverse gap-3 w-full items-center'>
            <textarea type="text" name='SecondParagraph' className='bg-slate-500 opacity-[80%] w-full rounded-md ' onChange={handleChange} value={data.SecondParagraph}/>
            <label htmlFor="SecondParagraph" className='min-w-fit bg-[#228699] text-white px-4 py-3 rounded-2xl'>Second Paragraph:</label>

          </div>
          <div className=' flex flex-row-reverse gap-3 w-full items-center'>
            <textarea type="text" name='ThirdParagraph' className='bg-slate-500 opacity-[80%] text-white w-full rounded-md' onChange={handleChange} value={data.ThirdParagraph}/>
            <label htmlFor="ThirdParagraph" className='min-w-fit bg-[#228699] text-white px-4 py-3 rounded-2xl' >Third Paragraph:</label>

          </div>
          <div className=' flex flex-row-reverse gap-3 w-full items-center'>
            <textarea type="text" name='FourthParagraph' className='bg-slate-500 opacity-[80%] text-white w-full rounded-md' onChange={handleChange} value={data.FourthParagraph}/>
            <label htmlFor="FourthParagraph" className='min-w-fit bg-[#228699] text-white px-4 py-3 rounded-2xl' >Fourth Paragraph:</label>
          </div>*/}

export default BlogForm