import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingSkeleton from "../../utils/loadingSkeleton/loadingSkeleton";
import AlertContext from "../../context/alertAndPopUp/alertAndPopUpContext";

const VerifyPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const url =
    process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
    const {alert} = useContext(AlertContext);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const reference = query.get("reference");
    const token = localStorage.getItem("soundmac3_token");


    const verifyPayment = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios.post(url + "/promotion/verify/payment", { reference },config);

        if (response.data.status === "success") {
          alert("Payment successful!");
          navigate("/promotion");
        } else {
          alert("Payment verification failed.");
          navigate("/promotion");
        }
      } catch (error) {
        console.error("Verification failed:", error);
      }
    };

    if (reference) {
      verifyPayment();
    }
  }, [location, navigate]);

  return <div className="min-h-[100dvh] flex justify-center items-center">
    <LoadingSkeleton />
    </div>;
};

export default VerifyPayment;
