import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import { home_post } from "../data/index";
import logo from "../../assets/icons/logo.svg";
import { MoveDown, Share2 } from "lucide-react";
import Loader from "../../blogComponents/LoaderComponent";
import axios from "axios";
import DOMPurify from 'dompurify';
import "./blogPost.css"
import { Helmet } from "react-helmet-async";
import logo2 from "../../assets/icons/logo192.png";

const BlogPost = () => {
  const url = process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
  const { Title } = useParams(); // Extract the article ID from the URL
  const [article, setArticle] = useState({});
  const [loading,setLoading] = useState(true);
  const [post, setPost] = useState([]);

  function slugify(title) {
    return title
      .toLowerCase()
      .trim()
      .replace(/[\s]+/g, '-') // Replace spaces with dashes
      .replace(/[^\w-]+/g, ''); // Remove special characters
  }
  const handleShare = async (item) =>{
    if (navigator.share){
      try {
        await navigator.share({
          title: item.Title,
          text: item.paragraph1,
          url: "/posts/"+slugify(item.Title),
        });
        await axios.post(`${url}/posts/${item._id}/share`);
      } catch (error) {
        console.error('Error sharing', error);
      }
    }else{
      alert("browser not supported")
    }
    }

  // useEffect(()=> {
  //   const getParticularPost = async () => {
  //     console.log(encodeURIComponent(Title));
  //     console.log(Title);
  //     try {
  //       const response = await axios.get(`${url}/posts/${encodeURIComponent(Title)}`);
  //       setArticle(response.data)
  //       // console.log(response.data)
  //     } catch (error) {
  //       console.log(error.message)
  //     }finally{
  //       setLoading(false)
  //     }
  //   };
  //   getParticularPost();
  // }, [Title]);

  useEffect(()=> {
    const allPosts = async () => {
      try {
        const response = await axios.get(`${url}/posts/`);
        setPost(response.data)
        const mainPost = response.data.find((item)=> {
          return slugify(item.Title) == Title
        });
        setArticle(mainPost)
        console.log(mainPost);
      } catch (error) {
        console.log(error.message)
      }finally{
        setLoading(false)
      }
    };
    allPosts();
  }, [Title]);

  if (loading) {
    return <Loader />;
  }

  const formattedDate = new Date(article.date).toISOString().split('T')[0];
  const blogTitle = article.Title;
  const blogDescription = article.paragraph1;

  return (
    <div>
      <Helmet>
        <title>{blogTitle}</title>
        <meta
          name="description"
          content={blogDescription}
        />
        <link rel="canonical" href={`/posts/${blogTitle}`} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="Music distribution, Industry news, Africa artists, Fan base"/>
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`/posts/${blogTitle}`} />
        <meta property="og:image" content={logo2} />
        <meta name="twitter:card" content="summary_large"/>
      </Helmet>
      <section className="bg-homebg py-10">
        <div className="mx-auto w-[70%] max-lg:w-[90%] pb-6">
          <p className="text_color font-semibold flex">
          <p className="underline"><Link to="/blog" className="inline">Blog</Link></p>{">"} <Link to="/blog"><p className="underline flex">Post</p></Link> {">"} <p><MoveDown /></p>
          </p>
          <article>
            <h1 className="text-2xl font-bold leading-normal text-text md:text-[2.8vw] truncate">
              {article.Title}
            </h1>
            <div className="flex gap-3 py-6">
              <img src={logo} alt="" className="rounded-full bg-black " />
              <div>
                <h3>Soundmac</h3>
                <p>Published {formattedDate||" "}</p>
              </div>
            </div>
            <div>
              <div className="">
                <img
                  src={(article.image1)} alt="main blog post primary image" className="rounded-[20px] max-h-[650px] w-full object-cover"
                />
              </div>
              <div className="my-10 grid grid-cols-1 gap-8 text-justify prose ">
              <div className="list-decimal" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.paragraph1) }} />
              <div className="list-decimal" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.paragraph2) }} />
              </div>
              <div className="grid gap-8">
                <div className="grid grid-cols-1 gap-8">
                  <img src={article.image2} alt="main blog post secondary image" className="rounded-[20px] max-h-[650px] w-full object-cover" />
                  <div dangerouslySetInnerHTML={{ __html: article.paragraph3 }} />
                </div>
                <div className="grid grid-cols-1 gap-8">
                  <img
                    src={article.image3} alt="main blog post secondary image" className="rounded-[20px] max-h-[650px] w-full object-cover"
                  />
                  <div dangerouslySetInnerHTML={{ __html: article.paragraph4 }} />
                </div>
              </div>
            </div>
          </article>
        </div>
        <hr className="border-1 my-10 border-gray-800" />
        <div className="p-6">
          <h1 className="text-2xl font-bold leading-normal text-text md:text-[2.8vw] mb-3">
            view other related posts
          </h1>
          <div className="flex flex-wrap justify-between gap-3">
            {post.slice(0, 3).map((item, index) => (
              <article
                  key={index}
                  className="flex justify-center rounded-2xl bg-white p-3 hover:shadow-lg h-[400px]"
                >
                  <div className="flex w-[400px] flex-col justify-between max-lg:w-full">
              <Link to={`/posts/${item.Title}`} key={item._id} onClick={window.scrollTo(0,300)}>
                    <div className="mb-4">
                      <img
                        src={item.image1}
                        alt="Blog post image"
                        className="w-full object-cover h-[250px]"
                      />
                    </div>
                    <h2 className="font-bold leading-normal text-text truncate">
                      {item.Title}
                    </h2>
              </Link>
                    <div className=" text-base">
                      <p className="mb-3 text-sm">{item.date}</p>
                      <p className="flex gap-3 text-icon">
                          <button onClick={()=> {
                            handleShare(item)
                          }}>
                            <Share2
                              stroke="#10414A"
                              className={"hover:scale-110 focus:scale-110"}
                            />
                          </button>
                          {item.shares}
                        </p>
                    </div>
                  </div>
                </article>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogPost;
