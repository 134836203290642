import React, { useContext, useState } from "react";
import AlertContext from "../../context/alertAndPopUp/alertAndPopUpContext";
import axios from "axios";
import Loader from "../../blogComponents/LoaderComponent";


const UploadSalesReport = () => {
  const url = process.env.REACT_APP_BACKEND_SERVER_URL || 'http://localhost:4000/api/v1';
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState("");
  const { alert } = useContext(AlertContext);

  // Handle file selection
  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload
  const onFileUpload = async () => {
    const token = localStorage.getItem("soundmac3_token");

    if (!date || date === "") {
      alert("Please select a date.");
      return;
    }
    if (!selectedFile) {
      alert("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("date", date);
    formData.append("salesReport", selectedFile);

    try {
    setLoading(true);
      const response = await axios.post(url +"/salesReport/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Success:", response.data);
      setDate("");
      setSelectedFile("");
      setLoading(false);
      alert(response.data.msg)
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      alert("error something went wrong")
    }
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="flex flex-col items-center h-[87dvh] bg-[#dce6f5]"> 
      <div className="bg-[#fff] flex flex-col rounded-md w-[50%] max-md:w-[90%] p-5 my-4">
        <div className="">
          <div className="flex justify-between">
            <h3 className="font-bold text-2xl text-center mb-4">
              Post Sales Report
            </h3>
          </div>

          <div className="flex justify-between items-baseline bg-[#dce6f5] p-4 mb-3 rounded-lg">
            <input
              placeholder="Sales Report Upload Date"
              type="date"
              name="release_date"
              className="w-full bg-transparent placeholder:text-black placeholder:font-semibold"
              onChange={(e) => {
                console.log(e.target.value);
                setDate(e.target.value)}}
              value={date}
              required={true}
            />
          </div>
          <div className="flex justify-between items-baseline bg-[#dce6f5] p-4 mb-3 rounded-lg">
            <input
              type="file"
              accept=".csv, .xls, .xlsx"
              onChange={onFileChange}
              className="w-full"
              required={true}
            />
          </div>
          <div className="flex justify-center">
            <button
              onClick={onFileUpload}
              className="bg-[#318ce7] text-white p-2 px-4 rounded-md"
            >
              Upload
            </button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default UploadSalesReport;
