import axios from "axios";
import { Pen, Trash2 } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../blogComponents/LoaderComponent";
import { Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AlertContext from "../../../context/alertAndPopUp/alertAndPopUpContext";

const NotificationsList = () => {
  const url =
    process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem("soundmac3_token");
  const { alert } = useContext(AlertContext);

  const getNotifications = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(url + "/notification/admin", config);
      console.log(response);

      setNotifications(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to get notifications: " + error);
      setLoading(false);
      alert(error.message);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this post?"
    );
    if (confirmDelete) {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        setLoading(true);
        const res = await axios.delete(
          `${url}/notification/admin/${id}`,
          config
        );
        console.log(res);

        if (res.status === 200) {
          setNotifications((prevPosts) =>
            prevPosts.filter((post) => post._id !== id)
          );
        }
        setLoading(false);
        console.log(res);
        alert(res.data?.msg || res.data?.err);
      } catch (error) {
        alert(error.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="bg-[#f4f4f4] flex flex-col rounded-md w-[50%] max-md:w-[90%] p-5 my-4">
      <div className="">
        <div className="flex justify-between">
          <h3 className="font-bold text-2xl text-center mb-4">
            List of sent Notifications
          </h3>
          <Link to="/super_admin/notification/create">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              className="ml-[100px]"
            >
              <AddIcon />
            </Fab>
          </Link>
        </div>
        <ul className="overflow-auto h-[400px]">
          {notifications.map((item, index) => (
            <li key={item._id} className="list-none">
              <div className="flex justify-between items-baseline bg-[#dce6f5] p-4 mb-3 rounded-lg">
                <div className="flex flex-col">
                  <p
                    style={{
                      fontFamily: "Inter",
                    }}
                    className="leading-normal md:text-[2vw] hover:opacity-80 truncate"
                  >
                    {item.title}
                  </p>
                  <p
                    style={{
                      fontFamily: "Inter",
                    }}
                    className="leading-normal md:text-[2vw] hover:opacity-80 truncate"
                  >
                    {item.to}
                  </p>
                </div>
                <div className="flex gap-3">
                  <button>
                    <Link to={`/super_admin/notification/edit/${item._id}`}>
                      <Pen className="hover:fill-red-500 fill-[#228699]" />
                    </Link>
                  </button>
                  <button
                    onClick={() => {
                      handleDelete(item._id);
                    }}
                  >
                    <Trash2 className="hover:stroke-red-500 stroke-[#228699] fill-black" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NotificationsList;
