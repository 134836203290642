/* eslint-disable react-hooks/exhaustive-deps */
import classes from "./salesReport.module.css";
import Back from "../../../utils/backBtn/backBtn";
import { useContext, useEffect, useState } from "react";
import SalesReportContext from "../../../context/salesReport/context";
import AnchorArrow from "../../../utils/icons/settingsIcon/anchorArrow/anchorArrow";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoaderBall from "../../../utils/loader/loaderBall/loaderBall";
import WithdrawalModal from "./withdrawalModal";
import { RiSearch2Line } from "react-icons/ri";
import axios from "axios";
import debounce  from "lodash.debounce";

export default function SalesReport() {
  const url = process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
  const {
    getSalesReport,
    report,
    loading,
    getDuePayment,
    gettingPayment,
    duePayment,
    setReport,
  } = useContext(SalesReportContext);
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  useEffect(() => {
    getSalesReport(page || "1");
    getDuePayment();
  }, []);

  const isFeedback = Object.keys(report).length > 0;
  const containsReport = isFeedback && report.report.length > 0;

  const next = () => {
    navigate(`?page=${parseInt(page || "1") + 1}`);
    getSalesReport(parseInt(page | "1") + 1);
  };

  const prev = () => {
    if (page) {
      navigate(`?page=${parseInt(page) - 1}`);
      getSalesReport(parseInt(page) - 1);
    }
  };

  const [withdrawModal, setWithdrawModal] = useState(false);
  const toggleWithdrawalModal = () => {
    setWithdrawModal(!withdrawModal);
  };
  const [searchValues, setSearchValues] = useState({
    searchQuery: ''
  })

  const handleDownload = () => {
    downloadCSV("sales_report.csv", report);
  };
  function downloadCSV(filename, report) {
    const csv = report.report.map((item) => {
      return `${item.sale_month},${item.track_artists},${item.upc},${item.isrc},${item.track_title},${item.quantity},${item.total_usd},${item.digital_service_provider},${item.territory}`;
    });
  
    csv.unshift(
      "Sales month,Artiste name,UPC,ISRC,Track,Qty,Total USD,Digital service provider,Territory"
    );
  
    const csvData = csv.join("\n");
  
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
  
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
  const updateQuery = (e) => { setSearchValues((prev) => ({ ...prev, searchQuery: e.target.value || e.target.innerText })) }
  const debounceQuery = debounce(updateQuery, 1000); 
  const handleSearch = async () => {
    console.log(searchValues);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("soundmac3_token")}`,
        },
      };
      const res = await axios.get(url+"/salesReport/search?searchQuery="+searchValues.searchQuery,config);
      // console.log(res.data);
      setReport(res.data);
      
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    handleSearch();
    console.log(report);
  }, [searchValues]);

  console.log(report);
  return (
    <div className={classes.container}>
      {withdrawModal && <WithdrawalModal func={toggleWithdrawalModal} />}
      <div className={classes.sub_container}>
        <Back show_arrow={true} title={"Sales report"} route={"/dashboard"} />
        <p className={classes.instruction}>
          For new users, you might not see your sales report till after 3
          months.
        </p>
        <p className={classes.instruction}>
          Note: You can only download the report in the current page.
        </p>
        {/* report */}
        {loading ? (
          <div className={classes.loader_container}>
            <div className={classes.loader_sub_container}>
              <LoaderBall />
            </div>
          </div>
        ) : (
          <>
            {isFeedback && (
              <div>
        <div className={classes.search_container}>
          <RiSearch2Line className={classes.search_icon } />
          <input onChange={debounceQuery} placeholder='Search Full UPC, track title, artist' type='text' className={classes.search_input } />
        </div>
                <div className={classes.page_top_container}>
                  <div className={classes.page_title_grouped}>
                    <p className={classes.page_title}>
                      Amount Eligible for withdrawal:
                      <span className={classes.emphasis}>
                        {" "}
                        {gettingPayment ? "Loading..." : `$ ${duePayment}`}
                      </span>
                    </p>

                    <div className={classes.top_action_btn_grouped}>
                      <p
                        className={
                          gettingPayment
                            ? classes.top_action_btn_bland
                            : classes.top_action_btn
                        }
                        onClick={toggleWithdrawalModal}
                      >
                        Process withdrawal
                      </p>
                      <p className={classes.top_action_btn}>
                        Withdrawal History
                      </p>
                      <p className={classes.top_action_btn} onClick={handleDownload}>
                        Download Report
                      </p>
                      <p className={classes.top_action_btn} onClick={togglePopup}>
                        Apply for Royalties
                      </p>
                    </div>
                    <div>
                      <p className={classes.instruction + " mb-4"}>
                        Note: Date range is the date the sales report was Uploaded.
                      </p>
                      <div className="flex gap-2 flex-wrap">
                      {report.allDates && report.allDates.length > 0 && report.allDates.map((item, index) => (
                        <button onClick={(e)=> {
                          console.log(e.target.innerText);
                          debounceQuery(e);
                        }} key={index} className="bg-[#228699] text-white p-1 px-2 rounded-md hover:bg-opacity-75">
                        {item}
                        </button>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={classes.page_title_grouped}>
                    <p className={classes.page_title}>
                      Page:{" "}
                      <span className={classes.emphasis}>{report.page}</span>
                    </p>
                    <p className={classes.page_title}>
                      Report result:{" "}
                      <span className={classes.emphasis}>
                        {`${report.skip} - ${
                          report.totalCount > report.limit
                            ? report.skip + report.limit
                            : report.totalCount
                        } out of ${report.totalCount}`}
                      </span>
                    </p>
                  </div>
                </div>

                {/* reports */}
                <div className="overflow-auto h-[600px]">
                <table className={classes.table + " w-full text-center border-collapse "}>
                  <thead>
                    <tr className="text-white">
                      <th className="sticky top-0 max-md:w-[400px] bg-[#228699]">Sales month</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">Artiste name</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">UPC</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">ISRC</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">Track</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">Qty</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">Total USD</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">Digital service provider</th>
                      <th className="sticky top-0 max-md:w-[300px] bg-[#228699]">Territory</th>
                    </tr>
                  </thead>
                  
                  <tbody className="text-[#5b5c64]">
                    {containsReport ? (
                      report.report.map((item, index) => (
                        <tr key={index} className="border-b border-[#e2e2e2] h-24 ">
                          <td className="max-lg:min-w-[250px]">
                            {new Date(item.sale_month).toLocaleDateString(
                              undefined,
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td className="max-lg:min-w-[250px]">{item.track_artists}</td>
                          <td className="max-lg:min-w-[250px]">{item.upc}</td>
                          <td className="max-lg:min-w-[250px]">{item.isrc}</td>
                          <td className="truncate max-w-32">{item.track_title}</td>
                          <td className="max-lg:min-w-[250px]">{item.quantity}</td>
                          <td className="max-lg:min-w-[250px]">${item.total_usd}</td>
                          <td className="max-lg:min-w-[250px]">{item.digital_service_provider}</td>
                          <td className="max-lg:min-w-[250px]">{item.territory}</td>
                        </tr>
                      ))
                    ) : (
                      <div className="w-full">
                        <div colSpan="9" className={classes.nothing_container}>
                          <p className={classes.nothing_text}>no result</p>
                          {report.page > 1 && (
                            <p onClick={prev} className={classes.nothing_btn}>
                              Go to previous page
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                  </tbody>
                </table>
                </div>

                {containsReport && (
                  <div className={classes.pagination_container}>
                    {report.page > 1 && (
                      <div
                        onClick={prev}
                        className={classes.pagination_individual_container}
                      >
                        <div className={classes.anchor_prev}>
                          <AnchorArrow />
                        </div>
                        <p>Prev</p>
                      </div>
                    )}

                    <div
                      onClick={next}
                      className={classes.pagination_individual_container}
                    >
                      <p>Next</p>
                      <AnchorArrow />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[400px]">
            <h2 className="text-xl max-sm:text-lg font-bold text-red-600 mb-4">Eligibility for Advance Royalties
            </h2>
            <ul>
            <li className=" text-sm max-sm:text-xs text-gray-700">
            Earnings History: At least 6 months of consistent royalty income with a minimum average of $500/month.
            </li>
            <li className=" text-sm max-sm:text-xs text-gray-700">
            Royalty Ownership: Must own or have rights to the royalties.
            </li>
            <li className=" text-sm max-sm:text-xs text-gray-700">
            Future Projections: Provide projected royalty earnings for the next 6–12 months.
            </li>
            <li className=" text-sm max-sm:text-xs text-gray-700">
            Soundmac Membership: Must have distributed at least 1 project through Soundmac and have an active subscription plan
            </li>
            <li className=" text-sm max-sm:text-xs text-gray-700">
            Minimum Request: Advance amount must be at least $1,000.
            </li>
            </ul>
            <div className="flex justify-end mt-4">
              <button
                onClick={togglePopup}
                className="px-4 py-2 bg-[#228699] text-white rounded mr-2 hover:bg-opacity-90"
              >
                Okay
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
