import { CircleCheck } from "lucide-react";
// import classes from "./pricing.module.css";
import PriceData from "./pricingData";
// import { Switch } from "@mui/material";
// import { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import logo2 from "../../assets/icons/logo192.png";


function Pricing() {
  return (
    <section className="bg-white">
      <PricingCard />
    </section>
  );
}
export default Pricing;

function PricingCard() {
  return (
    <>
      <Helmet>
        <title>Affordable Music Distribution Pricing - Soundmac Plans for African Artists</title>
        <meta name="description" content="Explore Soundmac's affordable music distribution price for independent artists, Record label & API & White label distribution. Get your music on major platforms like Spotify, Apple Music, TikTok and more. Choose a plan that suits your needs." />
        <link rel="canonical" href={`/pricing`} />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Music distribution, Industry news, Africa artists, Fan base, Price"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`/pricing`} />
        <meta property="og:image" content={logo2} />
        <meta name="twitter:card" content="summary_large" />
      </Helmet>
      <div className="max-w-[90%] mx-auto">
        <div className="pb-6">
          <div className="py-20">
            <h1 className="capitalize text-5xl font-bold leading-normal">
              pricing
            </h1>
            <p className="text-xl font-medium opacity-90">
            From individual creators to the biggest enterprises, we have a plan
            for you.
            </p>
          </div>
          <div className="grid grid-cols-3 gap-3 max-lg:grid-cols-2 max-md:grid-cols-1">
            <div className="h-full rounded-xl overflow-hidden">
              <p className="text-center bg-black text-white rounded-tl-lg rounded-tr-lg uppercase font-semibold opacity-0 pointer-events-none">
                most popular
              </p>
              <div className="border-2 shadow-lg p-3 rounded-xl h-full">
                <div className="mb-16">
                  <h1 className="font-semibold text-3xl capitalize">
                    Basic
                  </h1>
                  <p className="text-sm font-medium opacity-90">
                Our Basic Plan has everything you need to get started. Enjoy affordable distribution without compromising on quality.
                  </p>
                </div>
                <div className="flex flex-col max-w-fit gap-2 pointer-events-none opacity-0">
                  <p className="bg-[#e74c3c] rounded-3xl text-white uppercase text-center flex text-xs py-1 px-3 ">
                    first month 50% off
                  </p>
                  <p className="font-bold line-through opacity-50 text-xl">
                    $22
                  </p>
                </div>
                <span className="flex items-baseline ">
                  <h2 className="font-bold text-6xl ">$0.83</h2>
                  <sub className="font-semibold text-lg">/mo</sub>
                </span>
                <p className="font-bold opacity-50 text-xl">
                  $9.99 Billed annually
                </p>
                <p className="font-bold text-xl opacity-0 pointer-events-none">
                  $22 Billed annually
                </p>
                <Link
                  to={"/auth/register"}
                  className="uppercase font-bold rounded-3xl my-6 flex justify-center bg-black bg-opacity-10 hover:bg-black hover:bg-opacity-20 w-full py-2 px-3 "
                >
                  get started
                </Link>
                <ul className="p-3 bg-black bg-opacity-10 -m-3 rounded-bl-lg rounded-br-lg h-full">
                  {PriceData[0].included.map((item, index) => (
                    <li
                      key={item}
                      className="text-xs flex items-center gap-2 my-3"
                    >
                      <p className="flex items-baseline">
                        <CircleCheck width={10} />
                      </p>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="rounded-xl">
              <p className="text-center bg-black text-white rounded-tl-lg rounded-tr-lg uppercase font-semibold">
                most popular
              </p>
              <div className="border rounded-bl-lg rounded-br-lg shadow-lg p-3 border-black">
                <div className="mb-16">
                  <h1 className="font-semibold text-3xl capitalize">
                    independent artist
                  </h1>
                  <p className="text-sm font-medium opacity-90">
                    For professional musicians and independent artists, this
                    package offers advanced features.
                  </p>
                </div>
                <div className="flex flex-col max-w-fit gap-2">
                  <p className="bg-[#e74c3c] rounded-3xl text-white uppercase text-center flex text-xs py-1 px-3 opacity-0 pointer-events-none">
                    first month 50% off
                  </p>
                  <p className="font-bold line-through opacity-50 text-xl">
                    $22
                  </p>
                </div>
                <span className="flex items-baseline ">
                  <h2 className="font-bold text-6xl ">$1.49</h2>
                  <sub className="font-semibold text-lg">/mo</sub>
                </span>
                <p className="font-bold opacity-50 text-xl">
                  $17.99 Billed annually
                </p>
                <Link
                  to={"/auth/register"}
                  className="uppercase font-bold rounded-3xl my-6 flex justify-center bg-black text-white hover:bg-black hover:bg-opacity-70 w-full py-2 px-3 "
                >
                  Get Started
                </Link>
                <ul className="p-3 bg-black bg-opacity-10 -m-3 rounded-bl-lg rounded-br-lg h-full">
                  {PriceData[1].included.map((item, index) => (
                    <li
                      key={item}
                      className="text-xs flex items-center gap-2 my-3"
                    >
                      <p className="flex items-baseline">
                        <CircleCheck width={10} />
                      </p>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="overflow-hidden rounded-xl">
              <p className="text-center bg-black text-white rounded-tl-lg rounded-tr-lg uppercase font-semibold pointer-events-none opacity-0">
                most popular
              </p>
              <div className="border-2 shadow-lg p-3 rounded-xl h-full overflow-hidden">
                <div className="mb-16">
                  <h1 className="font-[600] text-3xl capitalize">mini label</h1>
                  <p className="text-sm font-medium opacity-90">
                    For small-scale music operations or music group.
                  </p>
                </div>
                <div className="flex flex-col max-w-fit gap-2 pointer-events-none opacity-0">
                  <p className="bg-[#e74c3c] rounded-3xl text-white uppercase text-center flex text-xs py-1 px-3 ">
                    first month 50% off
                  </p>
                  <p className="font-bold line-through opacity-50 text-xl">
                    $22
                  </p>
                </div>
                <span className="flex items-baseline mt-5">
                  <h2 className="font-bold text-6xl ">$5.83</h2>
                  <sub className="font-semibold text-lg">/mo</sub>
                </span>
                <p className="font-bold opacity-50 text-xl">
                  $69.99 Billed annually
                </p>
                <Link
                  to={"/auth/register"}
                  className="uppercase font-bold rounded-3xl my-6 flex justify-center bg-black bg-opacity-10 hover:bg-black hover:bg-opacity-20 w-full py-2 px-3 "
                >
                  get started
                </Link>
                <ul className="p-3 bg-black bg-opacity-10 -m-3 rounded-bl-lg rounded-br-lg h-full">
                  {PriceData[2].included.map((item, index) => (
                    <li
                      key={item}
                      className="text-xs flex items-center gap-2 my-3"
                    >
                      <p className="flex items-baseline">
                        <CircleCheck width={10} />
                      </p>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="overflow-hidden rounded-lg">
              <p className="text-center bg-black text-white rounded-tl-lg rounded-tr-lg uppercase font-semibold pointer-events-none opacity-0">
                most popular
              </p>
              <div className="border-2 shadow-lg p-3 rounded-xl h-full">
                <div className="mb-16">
                  <h1 className="font-semibold text-3xl capitalize">
                    full label
                  </h1>
                  <p className="text-sm font-medium opacity-90">
                    Designed for larger record labels and aggregators, this
                    package offers enterprise level.
                  </p>
                </div>
                <div className="flex flex-col max-w-fit gap-2 pointer-events-none opacity-0">
                  <p className="bg-[#e74c3c] rounded-3xl text-white uppercase text-center flex text-xs py-1 px-3 ">
                    first month 50% off
                  </p>
                  <p className="font-bold line-through opacity-50 text-xl">
                    $22
                  </p>
                </div>
                <span className="flex items-baseline ">
                  <h2 className="font-bold text-6xl ">$14.99</h2>
                  <sub className="font-semibold text-lg">/mo</sub>
                </span>
                <p className="font-bold opacity-50 text-xl">
                  $99.99 Billed annually
                </p>
                <Link
                  to={"/auth/register"}
                  className="uppercase font-bold rounded-3xl my-6 flex justify-center bg-black bg-opacity-10 hover:bg-black hover:bg-opacity-20 w-full py-2 px-3 "
                >
                  get started
                </Link>
                <ul className="p-3 bg-black bg-opacity-10 -m-3 rounded-bl-lg rounded-br-lg h-full">
                  {PriceData[3].included.map((item, index) => (
                    <li
                      key={item}
                      className="text-xs flex items-center gap-2 my-3"
                    >
                      <p className="flex items-baseline">
                        <CircleCheck width={10} />
                      </p>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="overflow-hidden rounded-xl">
              <p className="text-center bg-black text-white rounded-tl-lg rounded-tr-lg uppercase font-semibold pointer-events-none opacity-0">
                most popular
              </p>
              <div className="border-2 shadow-lg p-3 rounded-xl h-full">
                <div className="mb-16">
                  <h1 className="font-semibold text-3xl capitalize">
                    API & White Label
                  </h1>
                  <p className="text-sm font-medium opacity-90">
                    For Music distribution companies, major record labels and
                    music industry giants.
                  </p>
                </div>
                <div className="flex flex-col max-w-fit gap-2 pointer-events-none opacity-0">
                  <p className="bg-[#e74c3c] rounded-3xl text-white uppercase text-center flex text-xs py-1 px-3 ">
                    first month 50% off
                  </p>
                  <p className="font-bold line-through opacity-50 text-xl">
                    $22
                  </p>
                </div>
                <span className="flex items-baseline pointer-events-none opacity-0">
                  <h2 className="font-bold text-6xl ">$0</h2>
                  <sub className="font-semibold text-lg">/mo</sub>
                </span>
                <p className="font-bold text-xl opacity-0 pointer-events-none">
                  $22 Billed annually
                </p>
                <a
                  href={`mailto:${"admin@soundmac.co"}?subject=${encodeURIComponent(
                    `Api and White Label`
                  )}`}
                  className="uppercase font-bold rounded-3xl my-6 flex justify-center bg-black bg-opacity-10 hover:bg-black hover:bg-opacity-20 w-full py-2 px-3 "
                >
                  let's talk
                </a>
                <ul className="p-3 bg-black bg-opacity-10 -m-3 rounded-bl-lg rounded-br-lg h-full">
                  {PriceData[4].included.map((item, index) => (
                    <li
                      key={item}
                      className="text-xs flex items-center gap-2 my-3"
                    >
                      <p className="flex items-baseline">
                        <CircleCheck width={10} />
                      </p>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
