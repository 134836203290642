import React, { useContext, useEffect, useState } from "react";
import UserContext from "../../../context/user/userContext";
import { Pen, Trash2 } from "lucide-react";
import AlertContext from "../../../context/alertAndPopUp/alertAndPopUpContext";
import axios from "axios";
import Loader from "../../../blogComponents/LoaderComponent";

const Notifications = () => {
  const url =
    process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
  const { alert } = useContext(AlertContext);
  // const { getNotification, notification } = useContext(UserContext);
  const token = localStorage.getItem("soundmac3_token");
  const [notifications, setNotification] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNotification = async () => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await axios.get(`${url}/notification/`, config);
        const data = res.data;
        setNotification(data.unreadNotifications);
        setLoading(false);
        console.log("THIS IS THE API", data);
        // return data;
      } catch (error) {
        if (error.response && error.response.data && error.response.data.msg) {
          const err = error.response.data.msg;
          alert(err);
        } else {
          alert(`${error.message}!`);
        }
        setLoading(false);
      }
    };
    getNotification();
  }, []);

  const handleDelete = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setLoading(true);
      const res = await axios.delete(`${url}/notification`, config);
      setNotification(res.data.notifications);
      setLoading(false);
      alert(res.data?.msg);
    } catch (error) {
      console.error("Failed to delete: " + error);
      setLoading(false);
      alert(error.message);
    }
  };

  const markAsRead = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setLoading(true);
      console.log("this is 1");
      const res = await axios.patch(`${url}/notification`, "", config);
      setNotification(res.data.notifications);
      setLoading(false);
      alert(res.data?.msg || res.data?.err);
    } catch (error) {
      console.error("Failed to mark as read: " + error);
      setLoading(false);
      alert(error.message);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="bg-[#dce6f5] p-10">
      <div className="max-w-[600px] mx-auto my-auto bg-white h-[600px] overflow-y-hidden py-10 px-3 rounded-lg">
        <div className="p-5 bg-[#ff4500] rounded-md flex justify-between max-sm:flex-col max-sm:items-center">
          <h1 className="text-center text-white font-bold text-2xl max-sm:mb-2">
            Notifications
          </h1>
          <div className="flex items-center gap-1 ">
            <button
              onClick={() => {
                markAsRead();
              }}
              className=" px-3 py-1 rounded-lg bg-white hover:bg-[#0ff]"
            >
              Read All
            </button>
            <button
              onClick={() => {
                handleDelete();
              }}
              className="px-3 py-1 rounded-lg bg-white hover:bg-[#0ff]"
            >
              <Trash2 className=" fill-red-600" />
            </button>
          </div>
        </div>

        {notifications.length > 0 ? (
          <ul className="list-none p-0 mt-5 h-[450px] overflow-auto rounded-md">
            {notifications.map((item) => (
              <li
                key={item._id} // Ensure you have a unique key
                className={
                  " p-3 mt-2 rounded-lg " + (item.read ? "" : "bg-[#0ff]")
                }
              >
                <h2 className="font-bold text-xl max-md:text-base">
                  {item.from? item.from : "System"}
                </h2>
                <h3 className="font-semibold text-base max-md:text-sm">
                  {item.title}
                </h3>
                <div className="flex justify-between ">
                  <p className="text-sm max-md:text-xs">{item.message}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="h-[80%] flex justify-center">
            <p className="text-center my-auto ">No notification</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
