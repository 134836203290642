import React, { useContext, useEffect, useState } from "react";
import Back from "../../utils/backBtn/backBtn";
import classes from "./promotion.module.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../../context/alertAndPopUp/alertAndPopUpContext";
import axios from "axios";
import { ListSubheader } from "@mui/material";
import LoadingSkeleton from "../../utils/loadingSkeleton/loadingSkeleton";

const PromotionForm = () => {
  const { alert } = useContext(AlertContext);
  const navigate = useNavigate();
  const { package1 } = useParams();
  const token = localStorage.getItem("soundmac3_token");
  const url =
    process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
  const [artists, setArtist] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [Aoptions, setAoptions] = useState([]); //for the autocomplete module for songs and albums
  const [formval, setFormval] = useState({
    date: "",
    package: "",
    artist: "",
    song: "",
    country: package1 === "boomplay" ? "" : "Nigeria",
    packageName: package1,
    article: "",
    image: "",
  });
  const [dataUrl, setDataUrl] = useState({
    image: "",
  });

  const selectOptions = (name, val) => {
    setFormval((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  //get the array of approved songs and albums
  function extractUserData(userObject) {
    if (!userObject || typeof userObject !== "object") {
      return {
        artists: [],
        approvedSongs: [],
        approvedAlbums: [],
      };
    }

    const artists = [];
    const approvedSongs = [];
    const approvedAlbums = [];
    let options = [];

    const artistObject = userObject.artiste || {};

    for (const artistName in artistObject) {
      if (artistObject.hasOwnProperty(artistName)) {
        artists.push(artistName);

        const artistData = artistObject[artistName];

        // Extract approved songs
        const songs = artistData.songs || {};
        for (const songTitle in songs) {
          if (songs.hasOwnProperty(songTitle)) {
            const songData = songs[songTitle];
            if (songData.status === "approved") {
              approvedSongs.push({
                title: songData.song_title,
                category: "Song",
              });
            }
          }
        }

        // Extract approved albums
        const albums = artistData.albums || {};
        for (const albumTitle in albums) {
          if (albums.hasOwnProperty(albumTitle)) {
            const albumData = albums[albumTitle];
            if (albumData.status === "approved") {
              approvedAlbums.push({
                title: albumData.album_title,
                category: "Album",
              });
            }
          }
        }
      }
    }
    options = approvedAlbums.concat(approvedSongs); //added the approved albums and songs into one array
    return {
      artists,
      approvedSongs,
      approvedAlbums,
      options,
    };
  }

  // const getUser = async () => {

  //   try {
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //     }
  //   }
  //     const res = await axios.get(url+"/users/user", config);
  //     console.log(res);
  //     if (res.status === 401){
  //       alert("Please login to continue");
  //       navigate("/auth/login");
  //       return;
  //     }
  //   } catch (error) {
  //     // Handle error response
  //     if (error.response && error.response.status === 401) {
  //       alert("Please login to continue");
  //       navigate("/auth/login");
  //     } else {
  //       console.error("An error occurred:", error);
  //     }
  //   }
  // }

  // get user
  const getUser = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.get(`${url}/users/user`, config);

      if (res.status == 401) {
        alert("please login");
        return navigate("/auth/login");
      }
      const data = res.data;

      if (data.user.type === null) {
        return navigate("/select_account_type");
      }
      if (
        data.user.type &&
        data.user.role !== "admin" &&
        data.user.role !== "super_admin" &&
        !data.user.artiste
      ) {
        return navigate("/add_artiste");
      }
      setUser(data.user);
      const funreturn = extractUserData(data.user);
      setArtist(funreturn.artists);
      setAoptions(funreturn.options);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      alert(
        error.message == "Request failed with status code 401"
          ? "Authentication invalid"
          : error.message
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (!token) {
        alert("Please login to continue");
        navigate("/auth/login");
        return;
      }
      await getUser();
    };
    fetchUser();
  }, [package1]);

  const packagesList = [
    [
      "Bronze package (5 B-tier playlist + Push Notifications 2m+ Impressions) | N300,000",
      "Silver package (10 A-tier playlist + Push Notifications 5m+ Impressions) | N500,000",
      "Gold package (15 A-tier playlist + Push Notifications 10m+ Impressions) | N700,000",
      "Platinum package (20 A-tier playlist + Push Notifications 15m+ Impressions) | N1,000,000",
    ],
    [
      "Deezer Top 100 song chart | N400,000",
      "Deezer Top 50 song chart | N450,000",
      "Deezer Top 10 song chart | N500,000",
      "Deezer Top 1 - 10 song chart | N650,000",
    ],
    [
      "Shazam top 100 songs chart | N700,000",
      "Shazam top 50 songs chart | N900,000",
      "Shazam top 20 songs chart | N1,300,000",
      "Shazam top 10 songs chart | N2,500,000",
    ],
    [
      "Max fm | N150,000",
      "Mainland Fm | N150,000",
      "Hot fm | N150,000",
      "City fm | N180,000",
      "Rhythm fm | N200,000",
      "Naija fm | N200,000",
      "Naija fm | N200,000",
      "Wazobia fm | N250,000",
      "Beat Fm | N400,000",
      "Soundcity Radio | N550,000",
    ],
    [
      "This day | N70,000",
      "Guardian | N70,000",
      "Independent | N70,000",
      "The nation | N70,000",
      "Daily Trust | N100,000",
      "Vanguard | N100,000",
      "Blueprint | N100,000",
    ],
  ];

  let spackage;

  switch (package1) {
    case "boomplay":
      spackage = packagesList[0];
      break;
    case "deezer":
      spackage = packagesList[1];
      break;
    case "shazam":
      spackage = packagesList[2];
      break;
    case "radio":
      spackage = packagesList[3];
      break;
    case "onlinepress":
      spackage = packagesList[4];
      break;

    default:
      spackage = [];
      break;
  }
  const submitForm = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);

    try {
      if (formval.date === "") {
        alert("please select a date");
        setLoading(false);
        return;
      } else if (formval.country === "") {
        alert("please select a country");
        setLoading(false);
        return;
      } else if (formval.package === "") {
        alert("please select a package");
        setLoading(false);
        return;
      } else if (formval.artist === "") {
        alert("please select an artist");
        setLoading(false);
        return;
      } else if (formval.packageName === "") {
        alert("please select a package from promotion page");
        setLoading(false);
        return;
      } else if (
        formval.song === "Select a song or album" ||
        formval.song === ""
      ) {
        alert("please select a song or album");
        setLoading(false);
        return;
      }
      const formData = new FormData();
    // Append each field from formval to the FormData object
    for (const key in formval) {
      if (formval.hasOwnProperty(key)) {
        if (key === 'song') {
          // If the key is 'song', append its properties
          for (const songKey in formval.song) {
            if (formval.song.hasOwnProperty(songKey)) {
              formData.append(songKey, formval.song[songKey]);
            }
          }
        } else {
          formData.append(key, formval[key]);
        }
      }
    }
      const res = await axios.post(url + "/promotion", formData, config);
      console.log(res);
      if (res.status === 401) {
        alert("please login");
        setLoading(false);
        return navigate("/auth/login");
      }
      if (res.status === 400) {
        alert(res.data.msg);
        setLoading(false);
      }
      const { authorization_url } = res.data;
      window.location.href = authorization_url; // Redirect to Paystack payment page
      setFormval({
        date: "",
        package: "",
        artist: "",
        song: "",
        country: package1 === "boomplay" ? "" : "Nigeria",
        packageName: package1,
        article: "",
        image: "",
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      console.error(error.message);
      alert(
        error.message === "Request failed with status code 401"
          ? "Authentication invalid"
          : error.response.data.msg
      );

      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="h-[100dvh] bg-white">
        <LoadingSkeleton />
      </div>
    );
  }
  const readDataUrl = (name, val) => {
    if (!val) {
      return;
    }
    selectOptions("image", val);
    const reader = new FileReader();
    console.log(reader);
    reader.readAsDataURL(val);

    reader.onload = () => {
      const url = reader.result;
      setDataUrl((prev) => ({
        ...prev,
        [name]: url,
      }));
    };
  };
  return (
    <div
      className={
        classes.container + " bg-white h-[100dvh] max-md:h-[100dvh] w-full"
      }
    >
      <Back show_arrow={true} title={`Go Back`} route={`/promotion`} />

      <div
        className={classes.sub_container + " justify-center min-w-full h-full"}
      >
        {/* vertical line */}
        <div className={classes.vertical_line + " h-[700px] "}></div>
        <div className=" h-[600px] min-w-[500px] max-lg:min-w-[80%] overflow-auto">
          <p className={classes.question + " text-lg"}>Note</p>
          <ul className="mb-5 max-md:text-sm">
            <li>Only Approved Songs/Albums can be Promoted </li>
            {package1 === "onlinepress" && (
              <li>
                Artist must upload a clear image of themselves, that will be
                used for the promotion
              </li>
            )}
          </ul>
          <div className={classes.middle_section}>
            {package1 === "onlinepress" && (
              <div>
                <div className={classes.image_box + " min-w-[90%]"}>
                  {/* <div className={classes.image_container}> */}
                  <img
                    src={
                      dataUrl.image !== ""
                        ? dataUrl.image
                        : "https://sconchun.sirv.com/Untitled-1.png"
                    }
                    alt="cover art"
                    className={classes.image}
                  />
                  {/* </div>{" "} */}
                </div>
                <div className="flex justify-between items-baseline bg-[#dce6f5] p-4 mb-3 rounded-lg my-10">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      console.log(e.target.files[0]);

                      readDataUrl("image", e.target.files[0]);
                    }}
                    className="w-full"
                    required={true}
                  />
                </div>
              </div>
            )}
            {/* <div className="mt-[100px]"></div> */}
            <p className={classes.question + " pl-3 mt-5"}>
              When is the promotion starting( Expecting date)
            </p>

            <div className={classes.last_input_container + " w-full"}>
              <input
                className={classes.input_text}
                placeholder="release date"
                type="date"
                name="release_date"
                autoComplete="off"
                value={formval.date}
                onChange={(e) => {
                  selectOptions("date", e.target.value);
                }}
              />
            </div>

            <p className={classes.question + " pl-3 mt-10"}>Target Country</p>
            <div className={classes.input_container + " w-full "}>
              <input
                disabled={package1 !== "boomplay"}
                className={classes.input_text}
                placeholder="Country"
                type="text"
                name="country"
                autoComplete="off"
                value={package1 === "boomplay" ? formval.country : "Nigeria"}
                onChange={(e) => {
                  selectOptions("country", e.target.value);
                }}
              />
            </div>
            <p className={classes.question + " pl-3 my-5"}>Select a package</p>
            <Autocomplete
              className="w-full mb-5 "
              disablePortal
              options={spackage}
              renderInput={(params) => (
                <TextField {...params} label="Packages" />
              )}
              value={formval.package}
              onChange={(e, value) => {
                selectOptions("package", value);
                console.log(formval);
              }}
            />
            <p className={classes.question + " pl-3 my-5"}>Select a Artist</p>
            <Autocomplete
              className="w-full mb-5"
              disablePortal
              options={artists}
              renderInput={(params) => (
                <TextField {...params} label="Artists" />
              )}
              value={formval.artist}
              onChange={(e, value) => {
                selectOptions("artist", value);
                console.log(formval);
              }}
            />
            <p className={classes.question + " pl-3 my-5"}>
              {" "}
              Song title or Album title you want to promote
            </p>
            {Aoptions === "" ? (
              <p className=" text-center">No approved songs or albums yet </p>
            ) : (
              <Autocomplete
                className="w-full mb-5"
                disablePortal
                options={Aoptions}
                groupBy={(option) => option.category}
                getOptionLabel={(option) =>
                  option.title || "Select a song or album"
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select a song or album" /> //if you check this change the form validation
                )}
                renderGroup={(params) => (
                  <React.Fragment key={params.key}>
                    <ListSubheader>{params.group}</ListSubheader>
                    {params.children}
                  </React.Fragment>
                )}
                value={formval.song}
                onChange={(e, value) => {
                  selectOptions("song", value);
                  console.log(formval);
                }}
              />
            )}
            {package1 === "onlinepress" ? (
              <div>
                <p className={classes.question + " pl-3 mt-10"}>
                  Write an article for the Newspaper
                </p>
                <div
                  className={
                    classes.input_container + " min-w-full min-h-[200px]"
                  }
                >
                  <textarea
                    rows={3}
                    className={classes.input_text + " h-full w-full"}
                    placeholder="Article"
                    type="text"
                    name="article"
                    autoComplete="off"
                    value={formval.article}
                    onChange={(e) => {
                      selectOptions("article", e.target.value);
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            <button
              className={classes.upload_btn}
              onClick={() => {
                console.log(formval);
                submitForm();
              }}
            >
              submit
            </button>
          </div>
        </div>
        {/* vertical line */}
        <div className={classes.vertical_line + " h-[600px]"}></div>
      </div>
    </div>
  );
};

export default PromotionForm;
