import { MoveRight } from "lucide-react";
import React from "react";
import classes from "./promotion.module.css";
import { Link } from "react-router-dom";

const Promotion = () => {
  return (
    <div className=" bg-[#dce6f5]">
      <div className="h-[89dvh] max-md:h-[50dvh] relative">
        <img
          className="object-cover h-full w-full"
          src="https://soundmac1.s3.eu-north-1.amazonaws.com/blogImage/WhatsApp%20Image%202025-01-17%20at%2021.29.51_da522aa1.jpg"
          alt=""
        />
        <div className="absolute top-[30%] left-[10%] text-white ">
          <h1 className="capitalize font-bold text-[6vw] text-center">
            music promotion
          </h1>
          <p className="text-[4vw]">
            Don't just create music push it to the world. Consistency and
            promotion are the keys to success.
          </p>
        </div>
      </div>
      <div>
        <h1 className="font-bold capitalize text-center text-2xl my-20 animate-bounce">
          music Promotion
        </h1>
      </div>
      <div className="flex max-w-[80%] mx-auto justify-center items-center pb-10 flex-basis flex-wrap gap-10 text-center">
        <div className="bg-[#fff] flex flex-col rounded-lg w-96 shadow-2xl hover:-translate-y-5 duration-500 ease-in-out">
          <img
            className="rounded-md h-[250px]"
            src="https://soundmac1.s3.eu-north-1.amazonaws.com/blogImage/WhatsApp%20Image%202025-01-17%20at%2019.15.06_bab69026.jpg"
            alt=""
          />
          <div className="flex flex-col p-4 justify-center items-center gap-16">
            <div>
              <h2 className=" font-semibold text-lg">
                Boomplay Editorial playlist
              </h2>
              <p className="text-sm h-[40px]">
                Get featured on Boomplay’s top editorial playlists and amplify
                your reach to millions of music fans across Africa.
              </p>
            </div>
            <Link
              to={"/promotion/form/boomplay"}
              className={
                classes.anchor +
                " text-[fff] font-bold text-base border border-[#10414A] px-8 py-4 mb-4 flex rounded-lg cursor-pointer items-center gap-2 justify-center"
              }
            >
              Get Started{" "}
              <div className={classes.target_icon}>
                <MoveRight size={15} />
              </div>
            </Link>
          </div>
        </div>
        <div className="bg-[#fff] flex flex-col rounded-lg w-96 shadow-2xl hover:-translate-y-5 duration-500 ease-in-out">
          <img
            className="rounded-md h-[250px]"
            src="https://soundmac1.s3.eu-north-1.amazonaws.com/blogImage/WhatsApp%20Image%202025-01-17%20at%2018.51.30_67df6974.jpg"
            alt=""
          />
          <div className="flex flex-col p-4 justify-center items-center gap-16">
            <div>
              <h2 className=" font-semibold text-lg">Deezer Chart Promotion</h2>
              <p className="text-sm h-[40px]">
                GBoost your visibility with targeted promotion on Deezer charts,
                connecting your music to a global audience.
              </p>
            </div>
            <Link
              to={"/promotion/form/deezer"}
              className={
                classes.anchor +
                " text-[fff] font-bold text-base border border-[#10414A] px-8 py-4 mb-4 flex rounded-lg cursor-pointer items-center gap-2 justify-center"
              }
            >
              Get Started{" "}
              <div className={classes.target_icon}>
                <MoveRight size={15} />
              </div>
            </Link>
          </div>
        </div>
        <div className="bg-[#fff] flex flex-col rounded-lg w-96 shadow-2xl hover:-translate-y-5 duration-500 ease-in-out">
          <img
            className="rounded-md h-[250px]"
            src="https://soundmac1.s3.eu-north-1.amazonaws.com/blogImage/WhatsApp%20Image%202025-01-17%20at%2019.11.41_97bde6b8.jpg"
            alt=""
          />
          <div className="flex flex-col p-4 justify-center items-center gap-16">
            <div>
              <h2 className=" font-semibold text-lg">
                Shazam Nigeria Chart Promotion
              </h2>
              <p className="text-sm h-[40px]">
                Secure a spot on Shazam Nigeria’s trending charts and make your
                music discoverable to fans across the nation
              </p>
            </div>
            <Link
              to={"/promotion/form/shazam"}
              className={
                classes.anchor +
                " text-[fff] font-bold text-base border border-[#10414A] px-8 py-4 mb-4 flex rounded-lg cursor-pointer items-center gap-2 justify-center"
              }
            >
              Get Started{" "}
              <div className={classes.target_icon}>
                <MoveRight size={15} />
              </div>
            </Link>
          </div>
        </div>
        <div className="bg-[#fff] flex flex-col rounded-lg w-96 shadow-2xl hover:-translate-y-5 duration-500 ease-in-out">
          <img
            className="rounded-md h-[250px] object-cover object-bottom"
            src="https://soundmac1.s3.eu-north-1.amazonaws.com/blogImage/WhatsApp%20Image%202025-01-18%20at%2022.42.06_65029b80.jpg"
            alt=""
          />
          <div className="flex flex-col p-4 justify-center items-center gap-16">
            <div>
              <h2 className=" font-semibold text-lg">
                Nigeria Radio Promotion (30 Days Airplay)
              </h2>
              <p className="text-sm h-[40px]">
                Achieve nationwide recognition with 30 days of consistent
                airplay on Nigeria’s leading radio stations.
              </p>
            </div>
            <Link
              to={"/promotion/form/radio"}
              className={
                classes.anchor +
                " text-[fff] font-bold text-base border border-[#10414A] px-8 py-4 mb-4 flex rounded-lg cursor-pointer items-center gap-2 justify-center"
              }
            >
              Get Started{" "}
              <div className={classes.target_icon}>
                <MoveRight size={15} />
              </div>
            </Link>
          </div>
        </div>
        <div className="bg-[#fff] flex flex-col rounded-lg w-96 shadow-2xl hover:-translate-y-5 duration-500 ease-in-out">
          <img
            className="rounded-md h-[250px]"
            src="https://soundmac1.s3.eu-north-1.amazonaws.com/blogImage/WhatsApp%20Image%202025-01-18%20at%2022.42.05_eaf5b148.jpg"
            alt=""
          />
          <div className="flex flex-col p-4 justify-center items-center gap-16">
            <div>
              <h2 className=" font-semibold text-lg">
                Online Press (Newspaper Article Publication)
              </h2>
              <p className="text-sm h-[40px]">
                Increase your credibility and reach with strategic features in
                top online newspapers, connecting your music to a broader
                audience.
              </p>
            </div>
            <Link
              to={"/promotion/form/onlinepress"}
              className={
                classes.anchor +
                " text-[fff] font-bold text-base border border-[#10414A] px-8 py-4 mb-4 flex rounded-lg cursor-pointer items-center gap-2 justify-center"
              }
            >
              Get Started{" "}
              <div className={classes.target_icon}>
                <MoveRight size={15} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promotion;
