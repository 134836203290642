import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import Loader from './LoaderComponent';
import { useParams } from "react-router-dom";
import AlertContext from "../../../context/alertAndPopUp/alertAndPopUpContext";
import Loader from "../../../blogComponents/LoaderComponent";

const NotificationsForm = () => {
  const { id } = useParams() || null;
  const url =
    process.env.REACT_APP_BACKEND_SERVER_URL || "http://localhost:4000/api/v1";
  const { alert } = useContext(AlertContext);
  const token = localStorage.getItem("soundmac3_token");
  const [loading, setLoading] = useState(false);
  const [notVals, setNotVals] = useState({
    title: "",
    message: "",
    to: "",
  });
  const handleChange = (e) => {
    const { value, name } = e.target;
    setNotVals((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  async function onSubmit(e) {
    e.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setLoading(true);
      console.log(notVals);
      if (
        isValidEmail(notVals.to.trim()) ||
        notVals.to.trim().toLowerCase() == "all"
      ) {
        if (id) {
          const res = await axios.patch(
            url + "/notification/admin/" + id,
            notVals,
            config
          );
          console.log(res);
          setLoading(false);
          return alert(res.data?.msg || res.data?.err);
        } else {
          console.log(notVals);
          const res = await axios.post(
            url + "/notification/admin/create",
            notVals,
            config
          );
          console.log(res);
          setLoading(false);
          return alert(res.data?.msg || res.data?.err);
        }
      }
      setLoading(false);
      alert("please put a valid email address or All");
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert(error);
    }
  }
  //fill the form with the post you want to edit
  useEffect(() => {
    if (id) {
      getNotification(id);
    }
  }, [id]);

  //get the post you want to update and update the states
  const getNotification = async (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setLoading(true)
      const response = await axios.get(
        `${url}/notification/admin/${id}`,
        config
      );
      console.log(response.data);
      setNotVals({
        ...response.data,
      });
      setLoading(false);
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };
  
  if (loading) {
    return <Loader />;
  }
  return (
    <div className="bg-white rounded-md m-5 ">
      <div className="justify-center w-[70%] mx-auto  flex flex-col items-start ">
        <h1 className="text-2xl font-bold capitalize text-center py-5 mx-auto">
          Send Notifications
        </h1>
        <form
          onSubmit={onSubmit}
          className="flex flex-col gap-5 w-full items-start"
        >
          <div className=" flex flex-row-reverse gap-4 text-left w-full">
            <input
              type="text"
              name="title"
              className="bg-slate-500 opacity-[80%] text-white w-full rounded-md p-3 focus:outline-blue-900 placeholder:italic placeholder:text-[1rem] placeholder:font-semibold"
              onChange={handleChange}
              value={notVals.title}
              placeholder="Notification Title"
              required={true}
            />
          </div>
          <div className=" flex flex-row-reverse gap-4 text-left w-full">
            <input
              type="text"
              name="message"
              className="bg-slate-500 opacity-[80%] text-white w-full rounded-md p-3 focus:outline-blue-900 placeholder:italic placeholder:text-[1rem] placeholder:font-semibold"
              onChange={handleChange}
              value={notVals.message}
              placeholder="Notification message"
              required={true}
            />
          </div>
          <div className=" flex flex-row-reverse gap-4 text-left w-full">
            <input
              type="text"
              name="to"
              className="bg-slate-500 opacity-[80%] text-white w-full rounded-md p-3 focus:outline-blue-900 placeholder:italic placeholder:text-[1rem] placeholder:font-semibold"
              onChange={handleChange}
              value={notVals.to}
              placeholder="which User or All users?"
              required={true}
            />
          </div>
          <button
            type="submit"
            className="w-full bg-[#228699] text-white p-4 rounded-lg hover:opacity-90"
            disabled={loading}
          >
            submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default NotificationsForm;
