const PriceData = [
    {
        title: "free plan",
        subheading: "From individual creators to the biggest enterprises, we have a plan for you.",
        amount: 10,
        credits: "10k credits included",
        included: [
            "Free audio file converter (Upload any audio format)",
            "2 Songs distribution ",
            "You keep 85% Royalty ",
            "Free UPC & ISRC ",
            "YouTube content ID ",
            "Payment direct to your bank account ",
            "Spotify verified artist check mark",
            "Apple Music Artist verification ",
            "Release in within 14 days,",
        ]
    },
    {
        title: "free plan",
        subheading: "From individual creators to the biggest enterprises, we have a plan for you.",
        amount: 10,
        credits: "10k credits included",
        included: [
            "Everything in Basic Plan",
            "Unlimited distribution ",
            "Register your music on billboard Chart",
            "Keep 100% Royalty",
            "Keep 90% YouTube content ID ",
            "Playlist pitching ",
            "Release cover song ",
            "SoundMac Public Profile for artist ",
            "Smart link request ",
            "Monthly stream analytics ",
            "Customerizable Release & Pre-order date ",
            "Promotional opportunity ",
            "1 Video distribution ",
            "Eligible for Soundmac to invest in your music ",
        
        ]
    },
    {
        title: "free plan",
        subheading: "From individual creators to the biggest enterprises, we have a plan for you.",
        amount: 10,
        credits: "10k credits included",
        included: [
            "Everything in independent artist ",
            "Unlimited distribution for 10 artists ",
            "Royalty split",
            "Pitch for Tv & movie sync ",
            "5 Video distribution ",
            "Keep 95% YouTube content ID ",
            "Release priority songs in 48 hours",
            "Cover art creator",
            "Expert advice session ",
            "Release level country Restrictions ",
            "Advanced insights",
            "Invite team members to artist team",
            "Customize ITunes pricing",
        ]
    },
    {
        title: "free plan",
        subheading: "From individual creators to the biggest enterprises, we have a plan for you.",
        amount: 10,
        credits: "10k credits included",
        included: [
            "Everything in mini label ",
            "Unlimited distribution for unlimited artists ( label can distribute for up to 1000+ artists) ",
            "Keep 100% Royalties ",
            "Keep 100% YouTube content ID ",
            "Dedicated support team ",
            "Customizable label name ",
            "Recording location ",
            "Access to global marketing ",
            "RIAA gold & Platinum monitoring ",
            "Apply for advances ",
            "SoundExchange + Neighborhood Right ",
            "Contract template (base on requests) ",
            "Best license template (Base on request)",
            "Customer soundmac - label profile link ",
            "Billboard chart registration for each artists ",
            "Radio play promotion ",
            "Tv station promotion ",
            "Eligible to be invested to be SoundMac Partner",
        
        ]
    },
    {
        title: "free plan",
        subheading: "From individual creators to the biggest enterprises, we have a plan for you.",
        amount: 10,
        credits: "10k credits included",
        included: [
        	"Everything in full label ",
        	"Anti fraud tools ",
        	"Customer percent deal ",
        	"Users registration ",
        	"Monthly statement and payment ",
        	"All streaming platforms ",
        	"Work as sub-distributor",
        	"White label ",
        	"API documentation ",
        	"Bulk import release tool",
        
        ]
    }
]

export default PriceData
