import axios from 'axios'
import { Pen, Trash2 } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from './LoaderComponent'
import { Fab } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';


const Bloglist = () => {
    const url = process.env.REACT_APP_BACKEND_SERVER_URL || 'http://localhost:4000/api/v1';
    const [loading,setLoading] = useState(true);
    const [posts,setPost] = useState([])

const getPosts = async () => {
    try {
        const response = await axios.get(url + "/posts/");
        setPost(response.data);
        setLoading(false);
 
    } catch (error) {
        console.error("Failed to get posts: " + error);
        setLoading(false);
    }
}
const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this post?");
    if (confirmDelete){
        try {
            setLoading(true)
            const res = await axios.delete(`${url}/posts/delete/${id}`);
            if (res.status === 203){
                setPost(prevPosts => prevPosts.filter(post => post._id !== id));
            }
            setLoading(false)
            console.log(res)
            alert(res.data.message || res.data.error)
        } catch (error) {
            alert(error)
        }
    }
}

useEffect(()=>{
    getPosts();
},[])

    if (loading) {
        return <Loader />;
    }

  return (
    <div className='bg-[#f4f4f4] flex flex-col rounded-md w-[50%] max-md:w-[90%] p-5 my-4'>
        <div className=''>
            <div className='flex justify-between'>
                <h3 className='font-bold text-2xl text-center mb-4'>List of Blog Posts</h3>
                <Link to='/super_admin/posts/create' >
                    <Fab size="small" color="primary" aria-label="add" className='ml-[100px]'>
                    <AddIcon />
                    </Fab>
                </Link>
            </div>
            <ul className='overflow-auto h-[300px]'>
                {posts.map((item, index) => (
                    <li key={item._id} className='list-none'>
                        <div className='flex justify-between items-baseline bg-[#dce6f5] p-4 mb-3 rounded-lg'>
                            <p style={{
                                fontFamily: 'Inter'
                                }} className='leading-normal md:text-[2vw] hover:opacity-80 truncate'>{item.Title}</p>
                            <div className='flex gap-3'>
                                <button>
                                <Link to={`/super_admin/posts/edit/${item._id}`}  > 
                                        <Pen className='hover:fill-red-500 fill-[#228699]'/>
                                </Link>
                                </button>
                                <button onClick={()=>{
                                    handleDelete(item._id)
                                }}>
                                    <Trash2 className='hover:stroke-red-500 stroke-[#228699] fill-black'/>
                                </button>

                            </div>
                        </div>
                    </li>
                    
                ))}

            </ul>
        </div>
    </div>
  )
}

export default Bloglist